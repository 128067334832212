import React from 'react'
// import { Link } from 'react-router-dom';
//import { Carousel } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';


const HomepageCarouselComponent = () => {
    return (
        <>
            <Carousel fade>
                <Carousel.Item>
                    <img
                        className='d-block w-100'
                        style={{ maxHeight: '500px' }}
                        src="https://www.idfcfirstbank.com/content/dam/idfcfirstbank/images/blog/finance/what-is-pf-employee-provident-fund-717x404.jpg" alt="pf" />
                    <Carousel.Caption>
                        <h3> </h3>
                        <p> </p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img className='d-block w-100'
                        style={{ maxHeight: '500px' }}
                        src="https://www.indiafilings.com/learn/wp-content/uploads/2022/07/47th-GST-Council-Meeting.jpeg" alt="pf" />
                    <Carousel.Caption>
                        <h3> </h3>
                        <p> </p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className='d-block w-100'
                        style={{ maxHeight: '500px' }}
                        src="https://www.indiafilings.com/learn/wp-content/uploads/2019/08/Minimum-Wage-Regulations.jpg" alt="pf" />
                    <Carousel.Caption>
                        <h3> </h3>
                        <p> </p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className='d-block w-100'
                        style={{ maxHeight: '500px' }}
                        src="https://media.licdn.com/dms/image/D5612AQGuWo3YCgLmSA/article-cover_image-shrink_720_1280/0/1712901240932?e=2147483647&v=beta&t=xLc-7EfcQgSK24to_7YrDKe4g-Ofzx5Vc-YVq3m7gqU" alt="pf" />
                    <Carousel.Caption>
                        <h3> </h3>
                        <p> </p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className='d-block w-100'
                        style={{ maxHeight: '500px' }}
                        src="https://media.licdn.com/dms/image/D4D12AQEDo9D4eNLIvQ/article-cover_image-shrink_720_1280/0/1676917251579?e=2147483647&v=beta&t=5pYy9Vo1XozJ1CX7mSM2nRE43mOv8WYhE6srAhjd7Xg" alt="pf" />
                    <Carousel.Caption>
                        <h3> </h3>
                        <p> </p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className='d-block w-100'
                        style={{ maxHeight: '500px' }}
                        src="https://okcredit-blog-images-prod.storage.googleapis.com/2021/02/labourlaws2.jpg" alt="pf" />
                    <Carousel.Caption>
                        <h3> </h3>
                        <p> </p>
                    </Carousel.Caption>
                </Carousel.Item>
                {/* <Carousel.Item>
                    <img className='d-block w-100'
                        style={{ maxHeight: '500px' }}
                        src="https://www.mathurassociates.net/images/slider/banner-08.jpg" alt="pf" />
                    <Carousel.Caption>
                        <h3>Third slide label</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </Carousel.Caption>
                </Carousel.Item> */}
                {/* <Carousel.Item>
                    <img className='d-block w-100'
                        style={{ maxHeight: '500px' }}
                        src="https://www.mathurassociates.net/images/slider/banner-04.jpg" alt="pf" />
                    <Carousel.Caption>
                        <h3>Fourth slide label</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </Carousel.Caption>
                </Carousel.Item> */}
            </Carousel>
        </>
    )
}

export default HomepageCarouselComponent