import React from 'react';
import ReactDOM from 'react-dom/client';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from './components/Layout';
import ErrorPage from './components/ErrorPage';
import Home from './components/Home.jsx';
import AboutUs from './components/AboutUs.jsx';
import Blog from './components/Blog.jsx';
import ContactUs from './components/ContactUs.jsx';
import Inquiry from './components/Inquiry.jsx';
import LegalUpdates from './components/LegalUpdates.jsx';
import Accounting from './components/Services_components/Accounting.jsx';
import Apprentice from './components/Services_components/Apprentice.jsx';
import BuildingConst from './components/Services_components/BuildingConst.jsx';
import CertificationOrder from './components/Services_components/CertificationOrder.jsx';
import ChildLabour from './components/Services_components/ChildLabour.jsx';
import ContractLabour from './components/Services_components/ContractLabour.jsx';
import EmployeeCompensation from './components/Services_components/EmployeeCompensation.jsx';
import EmploymentExchange from './components/Services_components/EmploymentExchange.jsx';
import EqualRemunerationAct from './components/Services_components/EqualRemunerationAct.jsx';
import ESIC from './components/Services_components/ESIC.jsx';
import FactoryAct from './components/Services_components/FactoryAct.jsx';
import Granuity from './components/Services_components/Granuity.jsx';
import GST from './components/Services_components/GST.jsx';
import IndustrialEmployment from './components/Services_components/IndustrialEmployment.jsx';
import LabourCompliances from './components/Services_components/LabourCompliances.jsx';
import LabourLaw from './components/Services_components/LabourLaw.jsx';
import LWF from './components/Services_components/LWF.jsx';
import Maternity from './components/Services_components/Maternity.jsx';
import MinimumWages from './components/Services_components/MinimumWages.jsx';
import NOC from './components/Services_components/NOC.jsx';
import PaymentBonusAct from './components/Services_components/PaymentBonusAct.jsx';
import PF from './components/Services_components/PF.jsx';
import PT from './components/Services_components/PT.jsx';
import Registration from './components/Services_components/Registration.jsx';
import RULLaws from './components/Services_components/RULLaws.jsx';
import SexualHarassmentAct from './components/Services_components/SexualHarassmentAct.jsx';
import ShopAct from './components/Services_components/ShopAct.jsx';
import SubmissionReturn from './components/Services_components/SubmissionReturn.jsx';






const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      { path: "AboutUs", element: <AboutUs /> },
      { path: "Blog", element: <Blog /> },
      { path: "ContactUs", element: <ContactUs /> },
      { path: "Inquiry", element: <Inquiry /> },
      { path: "LegalUpdates", element: <LegalUpdates /> },


      { path: "Accounting", element: <Accounting /> },
      { path: "Apprentice", element: <Apprentice /> },
      { path: "BuildingConst", element: <BuildingConst /> },
      { path: "CertificationOrder", element: <CertificationOrder /> },
      { path: "ChildLabour", element: <ChildLabour /> },

      { path: "ContractLabour", element: <ContractLabour /> },
      { path: "EmployeeCompensation", element: <EmployeeCompensation /> },
      { path: "EmploymentExchange", element: <EmploymentExchange /> },
      { path: "EqualRemunerationAct", element: <EqualRemunerationAct /> },
      { path: "ESIC", element: <ESIC /> },


      { path: "FactoryAct", element: <FactoryAct /> },
      { path: "Granuity", element: <Granuity /> },
      { path: "GST", element: <GST /> },
      { path: "IndustrialEmployment", element: <IndustrialEmployment /> },
      { path: "LabourCompliances", element: <LabourCompliances /> },


      { path: "LabourLaw", element: <LabourLaw /> },
      { path: "LWF", element: <LWF /> },
      { path: "Maternity", element: <Maternity /> },
      { path: "MinimumWages", element: <MinimumWages /> },
      { path: "NOC", element: <NOC /> },


      { path: "PaymentBonusAct", element: <PaymentBonusAct /> },
      { path: "PF", element: <PF /> },
      { path: "PT", element: <PT /> },
      { path: "Registration", element: <Registration /> },
      { path: "RULLaws", element: <RULLaws /> },

      { path: "SexualHarassmentAct", element: <SexualHarassmentAct /> },
      { path: "ShopAct", element: <ShopAct /> },
      { path: "SubmissionReturn", element: <SubmissionReturn /> }
    ]
  }
])
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
