import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const RULLaws = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>Registration Under Various Labour Laws  </h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>Registration Under Various Labour Laws  </h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        Your one-stop destination for seamless registration under various labor laws. As a specialized consultancy firm, we understand the importance of compliance in today's complex regulatory landscape. Our dedicated team at SP Solicitor is committed to assisting businesses in navigating the intricacies of labor law registrations, ensuring they meet all legal requirements while fostering a conducive and compliant work environment.
                        At SP Solicitor, we offer a comprehensive suite of services tailored to meet the specific needs of our clients in the realm of labor law registrations. Whether you are a new business looking to register under applicable labor laws or an existing organization seeking to update and ensure compliance, our experts are well-versed in the registration processes of various labor laws. We provide guidance on registrations under laws such as the Employees' Provident Fund (EPF), Employees' State Insurance (ESI), and other relevant statutes, helping you navigate the procedural requirements with ease.
                        Choosing SP Solicitor as your partner in labor law registrations ensures a streamlined and efficient process, allowing you to focus on your core business activities. Our commitment to excellence and indepth knowledge of labor laws positions us as a trusted advisor, empowering businesses to stay compliant, minimize legal risks, and create a workplace that adheres to the highest standards of regulatory governance. Let SP Solicitor be your guide in navigating the labyrinth of labor law registrations, ensuring that your organization operates within the bounds of the law while promoting a culture of compliance and integrity.


                    </p>
                </div>
            </div>
        </div>
    )
}

export default RULLaws