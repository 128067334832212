import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const GST = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>GST (Goods and Services Tax)</h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>GST (Goods and Services Tax)</h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        <h6>GST REGISTRATION</h6>
                        GST (Goods and Services Tax) is essentially an Indirect tax which has been implemented to replace numerous taxes in India. Onlne GST registration was passed in the Parliament on 29th March 2017 yet it became effective on 1st July 2017 in India. As per the changes in the 32nd council meeting, the threshold limit for GST registration is 40 lakhs for the supplier of goods and 20 lakhs for the supplier of services. Along with that, the North-Eastern States have an option to choose between 20 lakhs and 40 lakhs. Once you have registered under this regime, you will receive a unique GSTIN (Goods and Service Tax Identification Number). There are various advantages of GST registration. You can also avail input tax credit and collect GST from recipients of goods and services. <br /> <br />
                        <h6>BENEFITS OF GST REGISTRATION</h6>
                        •	You can legally collect taxes from your customers and pass on the Tax Benefits to suppliers. <br />
                        •	Business Becomes 100% tax Compliant. <br />
                        •	You can Claim Input Tax Credit which you have paid on your purchases and improve profits. <br />
                        •	 GST certificate can be used as one of the documents while opening current account or Business Account. <br />
                        •	You can easily apply for various states and Central Government tenders if you have GSTN. <br />
                        •	Expand your business through various channels like Online, Import-Exports <br />
                        •	To start payment gateways and use mobile wallets GST number is used. <br /> <br />


                        <h6>WHO NEEDS GST REGISTRATION NUMBER?</h6>
                        •	Businesses having annual turnover morethan Rs 20 Lakhs Per Annum (Rs 10 Lakh for North Eastern States) <br />
                        •	If business is dealing in more than one state <br />
                        •	If your business has previous registration under VAT, Excise Laws, Service Tax Laws <br />
                        •	Selling your goods or services online (like selling on Amazon and Flipkart) <br />
                        •	If you are providing services and goods outside India. <br /> <br />

                        <h6>PENALTIES INVOLVED UNDER GST ACT</h6>
                        •	Not having GST Registration : 100% tax Due or Rs10,000. Whichever is higher <br />
                        •	Not giving GST invoice : 100% tax due or Rs10,000. Whichever is higher <br />
                        •	Incorrect Invoicing : Rs 25,000 <br />
                        •	Not filing GST Tax Returns : For Nil Return its Rs 20 Per Day, Regular Returns Rs 50 Per Day. <br />
                        •	Choosing Composition Scheme even if not eligible : 100% tax due or Rs10,000. Whichever is higher <br />


                    </p>
                </div>
            </div>
        </div>
    )
}

export default GST