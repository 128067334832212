import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const Registration = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>Registration</h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>Registration</h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        We offer Compliance, dedicated and intelligent staff in respect of registration a company under Labor Law registration Act, which enables the corporate to focus on their core business while ensuring them to be law abiding entities. It is Mandatory for any company or industry to be registered under Labour Registration Act. Registered company can save its time and trouble if its get a registration certificate.Â A company if not registered in any case that could be challenged by a competitor, against costing your company thousands of dollars in legal costs. <br />
                        •	Coordination with Labour Deparment Enforcement team at time of Inspection. <br />
                        •	Submitting various returns/reports to the PF Authorities. <br />
                        •	Submitting various returns/reports to the ESI Authorities. <br />
                        •	Receiving employee-wise statement from PF Authorities. <br />
                        •	Streamlining all declaration forms for existing employees of the company. <br />
                        •	Ensuring all employees has ESIC numbers/Temporary Cards. <br />
                        •	Ensuring monthly ESI deduction from the payroll. <br />
                        •	Remitting Employer and Employee contribution to ESIC. <br />
                        •	Submitting half and yearly returns with challan copies. <br />
                        •	Other Labour Law related services. <br />


                    </p>
                </div>
            </div>
        </div>
    )
}

export default Registration