import React from 'react'
import '../index.css'
import { Link } from 'react-router-dom'
import { FaFacebook, FaTwitter, FaPinterest, FaLinkedin } from "react-icons/fa";
import DropDown from './DropDown';

const Header = () => {
    return (
        <>
            <div className="Nav-Top">
                <div className="Nav-Top-Left">
                    <ul className='Contact-details'>
                        <li><a href="tel:+918010549275"><p>Tel: +918010549275</p></a></li>
                        <li><a href="mailto:spsolicitor7@gmail.com"><p>Email to: spsolicitor7@gmail.com</p></a></li>
                    </ul>
                </div>
                <div className="Nav-Top-Right">
                    <ul className='Social-Websites'>
                        <li className='Facebook'><a href="https://www.facebook.com/"><FaFacebook /></a></li>
                        <li className='Twitter'><a href="https://www.twitter.com/"><FaTwitter /></a></li>
                        <li className='Pinterest'><a href="https://www.pinterest.com/"><FaPinterest /></a></li>
                        <li className='Linkdin'><a href="https://www.linkedin.com/"><FaLinkedin /></a></li>
                    </ul>
                </div>
            </div>
            <div className="Nav-Bottom">
                <div className="Nav-Bottom-Left">
                    <div className="Nav-Bottom-Left-img"></div>
                </div>
                <div className="Nav-Bottom-Right">
                    <ul className='Nav-Bottom-Right-Content'>
                        <li className='home' id='nav-header'><Link to="/">Home</Link></li>
                        <li className='aboutus' id='nav-header'><Link to="./AboutUs">About Us</Link></li>
                        <li className='services' id='nav-header'><DropDown /></li>
                        <li className='legal' id='nav-header'><Link to="./LegalUpdates">Legal Updates</Link></li>
                        <li className='inquiry' id='nav-header'><Link to="./Inquiry">Inquiry</Link></li>
                        <li className='blog' id='nav-header'><Link to="./Blog">Blogs</Link></li>
                        <li className='contactus' id='nav-header'><Link to="./ContactUs">Contact Us</Link></li>
                    </ul>
                </div>
                {/* <div className="burger">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div> */}
            </div>
        </>
    )
}

export default Header