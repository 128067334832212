import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const NOC = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>NOC (Fire & Pollution) </h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>NOC (Fire & Pollution) </h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        Welcome to SP Solicitor, your go-to partner for securing essential NOCs (No Objection Certificates) for Fire and Pollution compliance. In today's regulatory landscape, adherence to safety and environmental standards is paramount for businesses. At SP Solicitor, we specialize in providing comprehensive services to facilitate the smooth acquisition of NOCs related to fire safety and pollution control. <br />
                        Navigating the complexities of obtaining NOCs for Fire and Pollution can be a challenging task, but with
                        SP Solicitor, you can streamline the process effortlessly. Our team of experts is well-versed in the intricacies of regulatory requirements, ensuring that your business meets the necessary safety and environmental standards. Whether you are setting up a new venture or seeking to update and optimize your existing compliance framework, SP Solicitor offers tailored solutions to guide you through the NOC acquisition process. <br />
                        Choosing SP Solicitor as your partner for NOC (Fire & Pollution) compliance means choosing a commitment to safety, environmental responsibility, and regulatory excellence. Our dedicated professionals work closely with businesses to assess their specific needs, provide expert advice, and navigate the regulatory landscape efficiently. Let SP Solicitor be your trusted advisor in acquiring the essential NOCs, ensuring that your business not only meets legal obligations but also prioritizes the wellbeing of your employees and contributes to a sustainable, environmentally conscious future.


                    </p>
                </div>
            </div>
        </div>
    )
}

export default NOC