import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const BuildingConst = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>Building and Other Construction Workers (Regulation of Employment, And Conditions of Service) Act, 1996.</h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>Building and Other Construction Workers (Regulation of Employment, And Conditions of Service) Act, 1996.</h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        Welcome to SP Solicitor, your guiding partner in understanding and complying with the Building and Other Construction Workers (Regulation of Employment and Conditions of Service) Act, 1996. Enacted to safeguard the rights and well-being of construction workers, this vital legislation regulates their employment conditions and ensures the provision of essential benefits. At SP Solicitor, we specialize in providing comprehensive services to construction businesses, guiding them through the intricacies of compliance with this significant Act. <br />
                        The Building and Other Construction Workers Act, 1996, focuses on improving the working conditions of construction workers, emphasizing their welfare and safety. Our expert team at SP Solicitor recognizes the unique challenges within the construction industry and offers a range of services to assist businesses in adhering to the Act. These services include compliance assessments, policy development, and legal guidance to ensure that your organization meets its statutory obligations. Whether you are a construction contractor, builder, or any other entity falling under the purview of the Act, our professionals are committed to ensuring that your practices align seamlessly with legal frameworks, contributing to a safer and more regulated construction environment. <br />
                        Choosing SP Solicitor as your partner for Building and Other Construction Workers Act compliance means choosing a commitment to worker welfare, legal precision, and industry-specific expertise. Our team combines legal knowledge with practical insights, providing you with tailored solutions that not only keep you compliant with the Act but also contribute to creating a construction workplace that prioritizes safety, fairness, and the well-being of its workforce. Trust SP Solicitor to be your guide in navigating the nuances of the Building and Other Construction Workers (Regulation of Employment and Conditions of Service) Act, 1996, ensuring that your business operates within the legal framework while prioritizing the welfare of construction workers.


                    </p>
                </div>
            </div>
        </div>
    )
}

export default BuildingConst