import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Link } from 'react-router-dom';

const DropDown = () => {
    return (
        <DropdownButton className='dropDown' title="Services">
            {/* first */}
            <Dropdown.Item><Link to="/LabourLaw">Law Labour Consultant</Link><Link to="/Granuity" style={{ marginLeft: '175px' }}>Gratuity Act Compliance</Link></Dropdown.Item>
            {/* second */}
            <Dropdown.Item><Link to="/ContractLabour" >Contract Labour Act</Link><Link to="/MinimumWages" style={{ marginLeft: '196px' }}>Minimum Wages Act</Link></Dropdown.Item>
            {/* third */}
            <Dropdown.Item><Link to="/RULLaws">Registration Under Various Laws</Link><Link to="/EqualRemunerationAct" style={{ marginLeft: '112px' }}>Equal Remuneration Act</Link></Dropdown.Item>
            {/* fourth */}
            <Dropdown.Item><Link to="/LabourCompliances">Labour Law Compliances</Link><Link to="/EmployeeCompensation" style={{ marginLeft: '163px' }}>Employee Compensation Act</Link></Dropdown.Item>
            {/* fifth */}
            <Dropdown.Item><Link to="/Registration">Registration</Link><Link to="/PaymentBonusAct" style={{ marginLeft: '254px' }}>Payment of Bonus Act</Link></Dropdown.Item>
            {/* sixth */}
            <Dropdown.Item><Link to="/ShopAct">Shop & Establishment Act</Link><Link to="/IndustrialEmployment" style={{ marginLeft: '154px' }}>Industrial Employment Standing Orders Act</Link></Dropdown.Item>
            {/* seven */}
            <Dropdown.Item><Link to="/NOC">NOC- Fire & Pollution</Link><Link to="/SexualHarassmentAct" style={{ marginLeft: '183px ' }}>Sexual Harassment Of Women At Workplace</Link></Dropdown.Item>
            {/* eight */}
            <Dropdown.Item><Link to="/SubmissionReturn">Submission of Returns</Link><Link to="/Maternity" style={{ marginLeft: '181px' }}>Maternity Benefit Act (1961)</Link></Dropdown.Item>
            {/* nine */}
            <Dropdown.Item><Link to="/CertificationOrder">Certification of Standing Order</Link><Link to="/Apprentice" style={{ marginLeft: '123px' }}>Apprentice Act (1961)</Link></Dropdown.Item>
            {/* ten */}
            <Dropdown.Item><Link to="/LWF">Labour Welfare Funds</Link><Link to="/ChildLabour" style={{ marginLeft: '184px' }}>Child Labour Act (1986)</Link></Dropdown.Item>
            {/* eleven */}
            <Dropdown.Item ><Link to="/FactoryAct">Factories Act</Link><Link to="/PT" style={{ marginLeft: '247px' }}>Professional Tax Consultant</Link></Dropdown.Item>
            {/* twelve */}
            <Dropdown.Item ><Link to="/ESIC">Employee's State Insurance Act</Link><Link to="/BuildingConst" style={{ marginLeft: '120px' }}>Building and Other Construction Workers Act(1996)</Link></Dropdown.Item>
            {/* thirteen */}
            <Dropdown.Item><Link to="/PF">Provident Fund</Link><Link to="/GST" style={{ marginLeft: '230px' }}>Good & Service Tax</Link></Dropdown.Item>
            {/* fourteen */}
            <Dropdown.Item style={{ display: 'flex' }}><Link to="/Accounting">Accounting Services</Link><Link to="/EmploymentExchange" style={{ marginLeft: '200px' }}>Employment Exchange</Link></Dropdown.Item>


        </DropdownButton >
    )
}

export default DropDown