import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const EqualRemunerationAct = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>Equal Remuneration Act (1976)</h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>Equal Remuneration Act (1976)</h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        Welcome to SP Solicitor, your trusted partner in navigating the tenets of the Equal Remuneration Act. Enacted to ensure gender equality in the workplace, the Equal Remuneration Act mandates that men and women receive equal pay for equal work. At SP Solicitor, we specialize in providing comprehensive services to businesses, guiding them through the nuances of compliance with the Equal Remuneration Act. <br />
                        Our expert team at SP Solicitor understands the significance of fostering a work environment that upholds principles of fairness and gender equality. We offer a range of services to assist businesses in adhering to the Equal Remuneration Act, including conducting gender-based pay audits, ensuring transparency in remuneration policies, and providing legal counsel to navigate the intricacies of compliance. Whether you are a startup, small business, or a large corporation, our professionals are dedicated to ensuring that your organization meets its obligations under the Equal Remuneration Act, contributing to a workplace that values diversity and equality. <br />
                        •	To maintain register of employees, register of sanitation and other matters required by the Act. <br />
                        •	To give a satisfactory answer to the show cause notice issued by the Inspector or any appropriate authority. <br />
                        •	To give suitable advice and assistance to establishments during the inspection and search of any premises by the Inspector. <br />
                        •	Representing the establishments at the time of inquiry made by the Inspector. <br />
                        •	Correspondence / Liaison with the concerned department/office.


                    </p>
                </div>
            </div>
        </div>
    )
}

export default EqualRemunerationAct