import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const LWF = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>Labour Welfare Funds (LWF)</h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>Labour Welfare Funds (LWF)</h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        Welcome to SP Solicitor, your dedicated partner in managing and optimizing Labour Welfare Funds (LWF). <br />
                        •	Calculation of contribution and deposited along with Form ‘A’ within prescribed time as per Act. <br />
                        •	Updating regarding new notifications and amendments done by Labour Department. <br />
                        •	All assessment & inspection from Labour department, when required. <br />
                        •	Reply of Notices etc. from the concern authorities. <br />
                        •	Maintenance of all statutory registers & submission of form required by the Labour Department. <br />
                        •	Sends reminder messages to clients to make contributions timely. <br />
                        •	To give better advice and assistance to establishments in the course of inspection and search of any premises by the Inspector. <br />


                    </p>
                </div>
            </div>
        </div>
    )
}

export default LWF