import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const ESIC = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>Employee’s State Insurance Act, 1948 (ESIC)</h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>Employee’s State Insurance Act, 1948 (ESIC)</h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        Welcome to SP Solicitor, your trusted partner in navigating the regulatory landscape governed by the Employee's State Insurance Act, 1948 (ESIC). This crucial piece of legislation was enacted with the aim of ensuring social security and financial protection to employees in case of sickness, disablement, or death due to employment-related injuries. At SP Solicitor, we specialize in providing comprehensive services to businesses, aiding them in understanding and complying with the provisions outlined in the ESIC Act. <br />
                        •	Assistance to employees in availing ESI benefits. <br />
                        •	Preparation and submission of the certificate of contribution as and when demanded by the appropriate office under the ESI Act. <br />
                        •	Assistance to establishment at the time of inspection and search of any premises under ESIC Act. <br />
                        •	Representing the establishments at the time of inquiry made by the Inspector. <br />
                        •	Correspondence / Liaison with local ESI Department / Office. <br />
                        •	Preparation, Maintenance, and Safe Maintenance of Form No. 7 (ESI Regulation No. 32).
                        •	Form No. 3 (Regulation 14). <br />
                        •	Generation of Challan through ESIC web portal and deposit in Bank within stipulated time as per Act. (Effective from August 10 as per the new amendment) Provide help and prepare the documents in case of accident or demised employee. <br />
                        •	All evaluation and inspection from the ESI department if required. <br />
                        •	Reply to notices etc. from the concerned authorities. <br />
                        •	Informing about any amendment/change in the Act. <br />
                        •	Preparation and processing of contingent claims under the ESI Act. <br />


                    </p>
                </div>
            </div>
        </div>
    )
}

export default ESIC