import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const ShopAct = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>Shop & Establishment Act</h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>Shop & Establishment Act</h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        We are offering following services for shops, residential hotels, commercial establishments, restaurants, theatres, eating houses and other places of public entertainment & other establishments. <br />
                        •	Arranging registration (for new establishments) <br />
                        •	Renewal of registration every year. <br />
                        •	Apprising the employer regarding maintenance of registers and records, as per the provisions of the Act. <br />
                        •	Keeping informed the employer from time to time regarding changes / amendments to the Act. <br />


                    </p>
                </div>
            </div>
        </div>
    )
}

export default ShopAct