import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const PaymentBonusAct = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>Payment Of Bonus Act (1965)</h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>Payment Of Bonus Act (1965)</h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        Welcome to SP Solicitor, your trusted advisor in navigating the regulations outlined in the Payment of Bonus Act, 1965. Enacted to recognize and reward the hard work of employees, this crucial legislation mandates the payment of bonuses to eligible workers in various establishments. At SP Solicitor, we specialize in providing comprehensive services to businesses, guiding them through the intricacies of compliance with the Payment of Bonus Act. <br />
                        •	Provide assistance and advice in matters relating to payment of bonus. <br />
                        •	Assistance when needed in calculating the exact amount of the bonus. <br />
                        •	Assistance in treatment of available surplus / allocable surplus such as set-on, set-off, carry forward. <br />
                        •	To maintain and regularize registers of various subjects such as allocable surplus, set on and set off of allocable surplus, bonus etc. under the Act. <br />
                        •	Preparation and submission of annual return as prescribed under the Act. <br />
                        •	Satisfactorily responding to the show cause notice issued by the Inspector. <br />
                        •	Representation of Establishments before the Inspector. <br />
                        •	To be present at the establishments at the time of inspection and search of any premises by the Inspector and to render necessary assistance. <br />
                        •	Representing establishments in inquiries made by the Inspector.


                    </p>
                </div>
            </div>
        </div>
    )
}

export default PaymentBonusAct