import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const Blog = () => {
    return (
        <div className='legalUpdate'>
            <Carousel fade>
                <Carousel.Item>
                    <img
                        className='d-block w-100'
                        style={{ maxHeight: '500px' }}
                        src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                    <Carousel.Caption>
                        <h3>Blogs</h3>
                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            <div className="legalUpdate-content">
                <h1>Blogs</h1>
            </div>
        </div>
    )
}

export default Blog