import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const Accounting = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>Accounting services</h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>Accounting services</h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        <h6>Bookkeeping:</h6>
                        Recording financial transactions, Managed the maintenance of books of accounts and ledgers, ensuring accurate and up-to-date financial records.

                        <h6>Payroll Services:</h6>
                        Managing employee payments and related taxes,
                        Review salary structures, allowances, deductions, and overtime calculations.
                        Check adherence to minimum wage laws, statutory requirements, and overtime regulations.

                        <h6>Tax Preparation:</h6>
                        Ensuring compliance with tax regulations and filing returns.

                        Ensured TDS compliances, including tax deposit and return filing, maintaining adherence to statutory norms.

                        Managed GST compliances, overseeing tax deposit and return filing in accordance with regulatory guidelines.

                        <h6>Financial Statements:</h6>
                        Generating income statements, balance sheets, and cash flow statements.

                        <h6>Advisory Services:</h6>
                        Providing financial advice and strategic planning.

                        <h6>Management Accounting:</h6>
                        Providing internal financial information for decision-making.


                    </p>
                </div>
            </div>
        </div>
    )
}

export default Accounting