import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const EmploymentExchange = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>Employment Exchange (Compulsory Notification Of Vacancies) Act (1959)</h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>Employment Exchange (Compulsory Notification Of Vacancies) Act (1959)</h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        Welcome to SP Solicitor, your trusted advisor in navigating the provisions of the Employment Exchange (Compulsory Notification of Vacancies) Act, 1959. This landmark legislation was enacted to facilitate employment opportunities for job seekers and streamline the labor market by requiring employers to notify job vacancies to employment exchanges. At SP Solicitor, we specialize in providing comprehensive services to businesses, guiding them through the intricacies of compliance with this essential Act. <br />
                        The Employment Exchange Act, 1959, mandates employers to notify designated employment exchanges about job vacancies, creating a platform for job seekers to access relevant employment opportunities. Our expert team at SP Solicitor understands the importance of adhering to these regulations to ensure transparency and equal access to employment. We offer a range of services to assist businesses in complying with the Employment Exchange Act, including compliance assessments, notification procedures, and legal guidance to ensure that your organization meets its statutory obligations. Whether you are a small business or a large corporation, our professionals are committed to ensuring that your organization actively participates in creating a fair and accessible job market. <br />
                        Choosing SP Solicitor as your partner for Employment Exchange Act compliance means choosing a commitment to fair employment practices, legal precision, and social responsibility. Our team combines legal expertise with practical insights, offering you tailored solutions that not only keep you compliant with the Employment Exchange Act but also contribute to creating a workplace that values inclusivity and equal opportunities. Trust SP Solicitor to be your guide in navigating the nuances of the Employment Exchange (Compulsory Notification of Vacancies) Act, 1959, ensuring that your business operates within the legal framework while actively participating in the enhancement of employment opportunities for the broader community.


                    </p>
                </div>
            </div>
        </div>
    )
}

export default EmploymentExchange