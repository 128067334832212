import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const Apprentice = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>Apprentice Act (1961)</h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>Apprentice Act (1961)</h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        Welcome to SP Solicitor, your guiding partner in navigating the regulations outlined in the Apprentice Act of 1961. This significant legislation aims to promote skill development and employment opportunities for young individuals by facilitating apprenticeship programs in various industries. At SP Solicitor, we specialize in providing comprehensive services to businesses, assisting them in understanding and complying with the Apprentice Act, fostering a culture of learning and professional development. <br />

                        •	Providing advice and assistance when required on matters relating to the Act. <br />
                        •	Drafting the appropriate apprenticeship contract. <br />
                        •	Registering the Apprenticeship Agreement with the Apprenticeship Adviser and giving necessary advice. <br />
                        •	To advise and assist in carrying out the obligations provided for under the Act. <br />
                        •	Responding to the show cause notice issued by the concerned department. <br />
                        •	Representing the establishments in case of any problems. <br />


                    </p>
                </div>
            </div>
        </div>
    )
}

export default Apprentice