import React from 'react'
// import { Link } from 'react-router-dom'

const AboutUsHome = () => {

    return (
        <div className="AboutUsHome-main">
            <div className="AboutUsHome-left"></div>
            <div className="AboutUsHome-right">
                <div className="AboutUsHome-right-Heading-one">
                    <h5>| About Us</h5>
                </div>
                <div className="AboutUsHome-right-Heading-two">
                    <h1>SP Solicitor</h1>
                </div>
                <div className="AboutUsHome-right-content">
                    <p>
                        The firm is lead under the supervision of Sr. Advocate Mr. Sajid Waris and Advocate Mr. Wasim Akram. SP Solicitor was established in the year 2012 and known as a leading Labour Law Service provider in India.
                    </p>
                </div>
                {/* <div className="AboutUsHome-right-btn">
                    <Link to="./AboutUs"><button className="btn btn-warning">Read More</button></Link>
                </div> */}
            </div>
        </div>
    )
}

export default AboutUsHome