import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const EmployeeCompensation = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>Employee Compensation Act (1923)</h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>Employee Compensation Act (1923)</h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        Welcome to SP Solicitor, your dedicated partner in understanding and navigating the provisions of the Employee Compensation Act. Enacted to ensure financial protection for employees in the event of workrelated injuries or illnesses, the Employee Compensation Act is a cornerstone of workplace safety and welfare. At SP Solicitor, we specialize in providing comprehensive services to businesses, guiding them through the intricacies of compliance with the Employee Compensation Act.
                        Our expert team at SP Solicitor recognizes the importance of addressing workplace injuries and illnesses with empathy and efficiency. We offer a range of services to assist businesses in adhering to the Employee Compensation Act, including claims management, legal counsel on compliance, and support in developing robust safety protocols. Whether you are a small business or a large corporation, our professionals are committed to ensuring that your organization meets its obligations under the Employee Compensation Act, contributing to a safer and more secure work environment. <br />
                        Choosing SP Solicitor as your partner for Employee Compensation Act compliance means choosing a commitment to employee welfare, legal precision, and workplace safety. Our team combines legal expertise with practical insights, providing you with tailored solutions that not only keep you compliant with the Employee Compensation Act but also contribute to creating a workplace that prioritizes the well-being and financial security of its employees. Trust SP Solicitor to be your guide in navigating the complexities of the Employee Compensation Act, ensuring that your business operates within the legal framework while fostering a culture of safety and support.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default EmployeeCompensation