import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const ChildLabour = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>Child Labour (Prohibition And Regulation) Act (1986)</h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>Child Labour (Prohibition And Regulation) Act (1986)</h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        Welcome to SP Solicitor, your committed partner in upholding ethical employment practices and navigating the stringent regulations outlined in the Child Labour (Prohibition and Regulation) Act. Enacted to eliminate the exploitation of children in the workforce, this crucial legislation prohibits the employment of children below a certain age and regulates the working conditions of adolescents. At SP Solicitor, we specialize in providing comprehensive services to businesses, guiding them through the complexities of compliance with the Child Labour Act, fostering a workplace that prioritizes the welfare of children. <br />
                        •	To inform and ensure compliance of rules under the Act. <br />
                        •	To respond appropriately to the show cause notice issued by the Inspector or any. appropriate authority. <br />
                        •	Assistance to establishments at the time of inspection and search of any premises by the Inspector. <br />
                        •	Representing the establishments at the time of inquiry made by the Inspector. <br />
                        •	Correspondence / Liaison with the concerned Department / Office. <br />
                        •	Responding to and settlement of claims against the Company. <br />


                    </p>
                </div>
            </div>
        </div>
    )
}

export default ChildLabour