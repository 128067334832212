import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const SexualHarassmentAct = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>Sexual Harassment Of Women At Workplace</h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>Sexual Harassment Of Women At Workplace</h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        Welcome to SP Solicitor, your dedicated partner in fostering safe and respectful workplaces through compliance with the Prevention of Sexual Harassment of Women at Workplace Act. Recognizing the importance of creating environments free from harassment, our expert team is committed to guiding businesses through the intricacies of compliance with this critical legislation. <br />
                        The Prevention of Sexual Harassment of Women at Workplace Act mandates organizations to establish a framework that prevents and addresses instances of sexual harassment. At SP Solicitor, we offer comprehensive services to assist businesses in creating a safe and inclusive work environment. Our expert professionals provide assistance in formulating and implementing anti-harassment policies, conducting awareness programs, and handling complaints in line with the legal requirements of the Act. Whether you are a small business or a large corporation, our commitment is to help your organization meet its obligations under the Prevention of Sexual Harassment Act, fostering a workplace culture that prioritizes the safety and dignity of every employee. <br />
                        Choosing SP Solicitor as your partner for Prevention of Sexual Harassment Act compliance means choosing a commitment to a workplace free from harassment, legal precision, and employee well-being. Our team combines legal expertise with practical insights, offering you tailored solutions that not only keep you compliant with the Act but also contribute to creating an environment that upholds the principles of equality and respect. Trust SP Solicitor to be your guide in navigating the nuances of the Prevention of Sexual Harassment of Women at Workplace Act, ensuring that your business operates within the legal framework while prioritizing a culture of safety, dignity, and inclusivity.

                    </p>
                </div>
            </div>
        </div>
    )
}

export default SexualHarassmentAct