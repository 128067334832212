import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const PT = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>Professional Tax (PT) Consultant</h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>Professional Tax (PT) Consultant</h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        Welcome to SP Solicitor, your trusted Professional Tax (PT) Consultant, dedicated to simplifying the complexities of professional tax compliance. Professional Tax is a state-level tax imposed on individuals engaged in various professions and trades. At SP Solicitor, we understand the nuances of Professional Tax regulations across different states and offer expert guidance to businesses and professionals, ensuring seamless compliance with the applicable laws. <br />
                        •	To render necessary assistance during inspection of accounts and documents and search of premises. <br />
                        •	Appealing and advising the appellate authorities. <br />
                        •	Compounding of offenses. <br />
                        •	Professional tax payment, return filing and assistance. <br />
                        •	Assistance in Calculating Professional Tax (PT) to Run Payroll. <br />
                        •	Filling up registration or nomination under Professional Tax. <br />
                        •	Advising and assisting in the deduction and payment of business tax. <br />
                        •	Preparation and timely filing of periodic invoices. <br />
                        •	Assistance in recovery proceedings. <br />


                    </p>
                </div>
            </div>
        </div>
    )
}

export default PT