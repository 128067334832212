import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const MinimumWages = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>Minimum Wages Act (1948)</h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>Minimum Wages Act (1948)</h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        Welcome to SP Solicitor, your trusted advisor in navigating the provisions of the Minimum Wages Act, 1948. As a foundational piece of legislation, the Minimum Wages Act aims to ensure fair compensation for labor and prevent exploitation of workers by setting minimum wage standards across different industries. At SP Solicitor, we specialize in providing comprehensive services to businesses, guiding them through the complexities of compliance with the Minimum Wages Act. <br />
                        •	Providing consultancy on matters concerned with Minimum Wages. <br />
                        •	To advise and assist in the payment of minimum wages at the rates applicable in the respective states. <br />
                        •	To advise in compliance of various provisions of the Act relating to rest, overtime, normal working hours, night shift etc. <br />
                        •	To ensure / assist in compliance of various provisions of the Act relating to disclosure, notice, performance, penalty, deduction etc. <br />
                        •	Preparation and maintenance of registers of various subjects such as fines, overtime, wages, deductions etc. as provided under the Act. <br />
                        •	Preparation and maintenance of various records, pay slips, muster rolls as provided under the Act. <br />
                        •	Preparation and submission of annual return as prescribed under the Act and in compliance with the necessary rules. <br />
                        •	Replying of Show Cause Notices. <br />
                        •	Representing the employer before the Inspector/Appropriate Govt. <br />
                        •	Assistance to companies during inspection and search of any premises by Inspector and to be present when required. <br />
                        •	Representing the employer in inquiries made by the Inspector.


                    </p>
                </div>
            </div>
        </div>
    )
}

export default MinimumWages