import React from 'react'

const Registration = () => {
    return (
        <div className="registration-page">
            <div className="registration-page-left">
            </div>
            <div className="registration-page-right">
                <div className="registration-page-right-heading">
                    <h5>| Company Registration</h5>
                </div>
                <div className="registration-page-right-content">
                    <p>
                        We are one of the leading service providers for registration under these laws EPF Act, SSI, Service Tax, Factories Act, 1948, The Contract Labour (R&amp;A) Act, 1970. We have a team of dedicated Industrial &amp; Labour Law Consultants who are well versed with Labour Law assignments. We regarded as the last destination for offering best quality services of Labour Law services provider in India. This registration act is apply to every establishment in which twenty or more workmen, art employed or were employed on any day of the preceding twelve months as contract Labour. This Act applies to every contractor / sub-contractor whom shall obtain a license from the licensing authority and such contractor / sub-contractor shall undertake or execute work through contract labour in accordance with terms and condition of licensing authority.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Registration