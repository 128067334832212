import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const LabourCompliances = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>Labour Law Compliances  </h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>Labour Law Compliances  </h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        Welcome to SP Solicitor, your trusted partner in ensuring comprehensive Labour Law compliances for businesses. Navigating the ever-evolving landscape of labor regulations can be a complex task, but with our expert team at SP Solicitor, you can rest assured that your organization remains compliant with all relevant labor laws. We specialize in providing tailored solutions to meet the unique compliance needs of businesses, regardless of their size or industry. <br />
                        At SP Solicitor, we understand that adherence to labor law compliances is not just about legal obligations; it's about fostering a workplace that prioritizes fairness, employee welfare, and organizational sustainability. Our dedicated team of professionals is well-versed in the intricacies of labor laws, offering a range of services that include compliance audits, policy reviews, and ongoing support to ensure that your organization stays abreast of any changes in the regulatory landscape. Whether you are establishing a new business or looking to enhance your existing compliance framework, SP Solicitor is your go-to partner for comprehensive Labour Law compliances. <br />
                        By choosing SP Solicitor, you are choosing a commitment to excellence, integrity, and a proactive approach to labor law compliances. Our goal is not only to keep you legally compliant but to empower your organization to create a workplace that thrives on ethical and responsible employment practices. Trust SP Solicitor to be your guide in navigating the complexities of Labour Law compliances, ensuring that your business operates seamlessly within the legal framework while promoting a culture of compliance, fairness, and success.


                    </p>
                </div>
            </div>
        </div>
    )
}

export default LabourCompliances