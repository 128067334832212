import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const IndustrialEmployment = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>Industrial Employment Standing Orders Act (1946)</h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>Industrial Employment Standing Orders Act (1946)</h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        Welcome to SP Solicitor, your trusted guide in understanding and complying with the provisions of the Industrial Employment Standing Orders Act. Enacted to promote industrial peace and define the conditions of employment, this crucial legislation outlines the rules and regulations that govern the terms of employment in industrial establishments. At SP Solicitor, we specialize in providing comprehensive services to businesses, assisting them in navigating the complexities of compliance with the Industrial Employment Standing Orders Act. <br />
                        Our expert team at SP Solicitor recognizes the importance of maintaining clear and standardized employment conditions to ensure harmony in the industrial work environment. We offer a range of services to assist businesses in adhering to the Industrial Employment Standing Orders Act, including drafting standing orders, compliance assessments, and legal guidance to ensure that your organization meets its statutory obligations. Whether you are a manufacturing unit, a processing plant, or any other industrial establishment, our professionals are dedicated to ensuring that your standing orders align seamlessly with legal frameworks. <br />
                        Choosing SP Solicitor as your partner for Industrial Employment Standing Orders Act compliance means choosing a commitment to clarity, legal precision, and harmonious industrial relations. Our team combines legal expertise with practical insights, offering you tailored solutions that not only keep you compliant with the Industrial Employment Standing Orders Act but also contribute to creating a workplace that values transparency and fairness in employment practices. Trust SP Solicitor to be your guide in navigating the nuances of the Industrial Employment Standing Orders Act, ensuring that your business operates within the legal framework while promoting a positive and well-regulated industrial atmosphere.


                    </p>
                </div>
            </div>
        </div>
    )
}

export default IndustrialEmployment