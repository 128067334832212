import React from 'react'

const Logo = () => {
  return (
    <div className="logo-main">
        <div className="logo-first">

        </div>
        <div className="logo-second">

        </div>
        <div className="logo-third">
            
        </div>
    </div>
  )
}

export default Logo