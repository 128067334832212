import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const ContractLabour = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>Contract Labour Act</h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>Contract Labour Act</h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        Our association is associated with competent staff as we are playing a great role in Labour Law Service providing in India. Economy and Efficiency is essence of business. Globalization, rigid archaic labour laws, need of flexible and efficient employment/work-practices, increasing cut-throat competition and question of survival etc. has necessitated discovering alternative employment system, i.e., Contract Labour System, or outsourcing as some call it. But contract labour system has brought in with it new challenges, legal as well as practical; hence this system needs its thorough understanding before its correct application in order to derive optimum benefits out of it.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ContractLabour