import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const PF = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>Employee’s provident funds & miscellaneous provisions act, 1952(PF) </h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>Employee’s provident funds & miscellaneous provisions act, 1952(PF) </h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        Welcome to SP Solicitor, your dedicated partner in navigating the complex landscape governed by the Employees' Provident Funds & Miscellaneous Provisions Act, 1952 (EPF). This landmark legislation was enacted to safeguard the financial well-being of employees by mandating provident fund contributions from both employers and employees. At SP Solicitor, we specialize in providing comprehensive services to businesses, guiding them through the intricacies of EPF compliance. <br />
                        •	Assistance to employees in withdrawal from EPF. <br />
                        •	Responding to show cause notices issued by Inspector/Commissioner. <br />
                        •	To advise and assist establishments in the course of inspection and search of any premises by the Inspector. <br />
                        •	Representing the establishments at the time of inquiry made by the Inspector. <br />
                        •	Correspondence / Liaison with the concerned EPF Department / Office. <br />
                        •	Responding to claims against the firm, establishing and assisting in arriving at fair settlement. <br />
                        •	Applying for and getting EPF registration/EPF code number. <br />
                        •	Sending monthly reminder for EPF payment. <br />
                        •	Preparation and filling of monthly challan with Employees' Provident Fund Authority. <br />
                        •	Regular maintenance and preparation of statutory registers and records as required under the EPF Act/Schemes. <br />
                        •	Providing daily consultation on matters related to EPF, Pension Scheme and Insurance Scheme.


                    </p>
                </div>
            </div>
        </div>
    )
}

export default PF