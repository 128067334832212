import React from 'react'
import { Outlet } from "react-router-dom"
import Header from './Header'
import Footer from './Footer'
import Whatsapp from './Whatsapp'
import Logo from './Logo'


const Layout = () => {
    return (
        <>
            <Header />
            <Outlet />
            <Footer />
            <Whatsapp />
            <Logo />
        </>
    )
}

export default Layout