import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const FactoryAct = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>Factories Act, (1948)</h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>Factories Act, (1948)</h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        Welcome to SP Solicitor, your guiding force in navigating the regulatory landscape governed by the Factories Act, 1948. This foundational piece of legislation plays a pivotal role in ensuring the safety, health, and welfare of workers employed in factories. At SP Solicitor, we specialize in providing comprehensive services to businesses, aiding them in understanding and complying with the provisions outlined in the Factories Act, 1948. <br />

                        •	Obtaining Registration Certificate under Factories Act. <br />
                        •	Renewal of Registration of Factory Act in Pune. <br />
                        •	Providing Day to day consulting solution on matter of Factory Act. <br />
                        •	Representing on behalf of Occupier/Manufactures at office of Factory Inspector. <br />
                        •	Replying & stratifying show cause Notices. <br />
                        •	To ensure compliance of various provisions of the Act relating to notice, display and disclosure and to render necessary assistance. <br />
                        •	Preparation and maintenance of various registers and records such as register of adult workers, register of child labour, register of compensatory leave, humidity register, register of wages, register of leave with wages, etc. <br />
                        •	Prepare and submit from time to time various forms, challans and reports as prescribed under the Factories Act. <br />


                    </p>
                </div>
            </div>
        </div>
    )
}

export default FactoryAct