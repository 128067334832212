import React from 'react'

const Whatsapp = () => {
  return (
    <div className="whatsapp-btn">
      <div className="icon-logo"><a href="https://wa.me/8010549275"><div className="whatsapp-logo"></div></a></div>
      
      <div className="circle-wave-first"></div>
      <div className="circle-wave-second"></div>
      <div className="circle-wave-third"></div>
    </div>
  )
}

export default Whatsapp