import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const Granuity = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>Gratuity Act Compliance</h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>Gratuity Act Compliance</h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        Welcome to SP Solicitor, your dedicated partner in ensuring seamless compliance with the Gratuity Act. Recognizing the importance of gratuity as a crucial employee benefit, our expert team is committed to guiding businesses through the intricacies of Gratuity Act Compliance. The Payment of Gratuity Act, 1972, mandates that organizations provide a gratuity payout to employees as a token of appreciation for their long-term service, and our specialized services are tailored to assist businesses in adhering to these legal provisions. <br />
                        •	To assist in determining the nature of injury as per the provisions of the Act such as permanent, temporary, partial disability, disability, total disability. <br />
                        •	Preparation of notices for accidents/serious bodily injuries and submission to the Commissioner. <br />
                        •	Assistance in medical examination of workers. <br />
                        •	To assist in the determination or calculation of the amount of compensation payable to the workman. <br />
                        •	To make reference and other assistance to the Commissioner for determination of the nature of disability, amount or period of compensation. br
                        •	Preparation and filing of applications and advice to the Commissioner under the Act. <br />
                        •	Drafting/registration of agreements to be entered into with the workman fixing the lump sum amount payable as compensation. <br />
                        •	Act in accordance with the various provisions of the Act relating to disclosure, notice, display, deduction, confiscation etc. <br />
                        •	To respond/satisfy the show cause notice issued by the Inspector/Commissioner as per rules. <br />
                        •	To represent the establishments before the Inspector/Commissioner. <br />
                        •	To be present and advise the Inspector at the time of inspection and search of any premises. <br />
                        •	To represent establishments in inquiries made by the Inspector


                    </p>
                </div>
            </div>
        </div>
    )
}

export default Granuity