import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const Maternity = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>Maternity Benefit Act (1961)</h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>Maternity Benefit Act (1961)</h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        Welcome to SP Solicitor, your trusted advisor in navigating the provisions of the Maternity Benefit Act. Enacted to safeguard the rights and well-being of working mothers, this pivotal legislation ensures that women employees receive maternity benefits during pregnancy and post-childbirth. At SP Solicitor, we specialize in providing comprehensive services to businesses, guiding them through the intricacies of compliance with the Maternity Benefit Act. <br />
                        The Maternity Benefit Act mandates that eligible women employees receive paid leave, allowing them the necessary time to care for themselves and their newborns. Our expert team at SP Solicitor recognizes the importance of supporting working mothers during this crucial phase of life. We offer a range of services to assist businesses in adhering to the Maternity Benefit Act, including compliance assessments, policy development, and legal guidance to ensure that your organization meets its statutory obligations. Whether you are a small enterprise or a large corporation, our professionals are dedicated to ensuring that your policies align seamlessly with legal frameworks, contributing to a supportive and inclusive work environment. <br />
                        Choosing SP Solicitor as your partner for Maternity Benefit Act compliance means choosing a
                        commitment to the well-being of working mothers, legal precision, and employee satisfaction. Our team combines legal expertise with practical insights, offering you tailored solutions that not only keep you compliant with the Maternity Benefit Act but also contribute to creating a workplace that values and supports its diverse workforce. Trust SP Solicitor to be your guide in navigating the nuances of the Maternity Benefit Act, ensuring that your business operates within the legal framework while prioritizing the health and happiness of your employees.

                    </p>
                </div>
            </div>
        </div>
    )
}

export default Maternity