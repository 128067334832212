import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const LabourLaw = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>Labour Law Consultant</h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>Labour Law Consultant</h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        We are one of the leading service providers for registration under these laws EPF Act, SSI, Service Tax, Factories Act, 1948, The Contract Labour (R&A) Act, 1970. We have a team of dedicated Industrial & Labour Law Consultants who are well versed with Labour Law assignments. We regarded as the last destination for offering best quality services of Labour Law services provider in India. This registration act is apply to every establishment in which twenty or more workmen, art employed or were employed on any day of the preceding twelve months as contract Labour. This Act applies to every contractor / sub-contractor whom shall obtain a license from the licensing authority and such contractor / sub-contractor shall undertake or execute work through contract labour in accordance with terms and condition of licensing authority. <br />
                        •	Providing latest information regarding amendments & solutions under Minimum Wages Act, 1948. <br />
                        •	Providing latest information regarding amendments under Contract Labour (Regulation & Abolition) Act, 1970 and The Uttar Pradesh Building and Other Construction Workers (Regulation of Employment and Condition of Service) Rule, 2009. <br />
                        •	Providing legal solutions for matters pertaining to Contract Labour (Regulation & Abolition) Act, 1970 and The Uttar Pradesh Building and Other Construction Workers (Regulation of Employment and Condition of Service) Rule, 2009. <br />
                        •	Providing latest information regarding amendments & legal solutions under Employees Provident Funds and Miscellaneous Provisions Act, 1952 <br />
                        •	Providing latest information regarding amendments & Legal solutions under State Employees Insurance Act, 1948. <br />
                        •	Providing legal solutions for matters pertaining to Industrial Disputes Act, 1947like: I.R. / Charge Sheet / illegal strikes / etc <br />
                        •	Providing legal solutions for matters pertaining to Factories Act, 1948 <br />
                        •	Providing legal solutions for matters pertaining to Payment of Bonus Act, 1965. <br />
                        •	Assistance in inspections of your organization by EPF / ESI / Labour Office & departments on time to time. <br />
                        •	Assistance in policy matters of your organisation on time to time under & based upon Labour Laws ( Central and State ) Acts & Rules. <br />
                        •	Providing legal solutions for matters pertaining to all other allied Labour Laws. <br />


                    </p>
                </div>
            </div>
        </div>
    )
}

export default LabourLaw