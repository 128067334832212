import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const AboutUs = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>About Us</h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>About SP Solicitor</h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        SP Solicitor is a professional esic pf consultant which provides services in the field of ESIC, PF, Factories Act, Labour Act, Payroll Processing, Minimum wages Act, and Bonus Act etc. We, are one of the leading ESI consultant company in Delhi NCR region, is specialized in dealing with the issues of handling and maintaining statutory records under various labour statute, which includes Employees Provident Fund & Miscellaneous Provision Acts 1952, ESI Act, Minimum Wages Act, Delhi Labor Welfare Rules 1997, Bonus Act, Factories Act 1948, and various other acts.
                        Working as a leading ESIC PF consultant company in Delhi NCR for the past 12 years, we have discovered the easiest way of dealing with the problems of our clients. We firmly believe in delivering high quality services and satisfactory solutions to our client’s ESIC and PF related problems and issues. Our experts and employees have deep knowledge and expertise in this field. We work hard and smart to provide reliable and trustworthy ESIC and PF consultancy services in Delhi.
                        Today, we are associated with a number of industries including Public Sector Undertakings, Builders & Developers, Hospitals, Nursing Homes, Engineering, Pharmaceuticals, I.T & Electronics Industries, Schools/Institutions, Embroidery, and Shops etc. With a team of experienced & proficient employees, we have been maintaining a good position in top ESIC PF consultant in Delhi NCR for the Past 12 years.

                    </p>
                </div>
            </div>
        </div>
    )
}

export default AboutUs