import React from 'react'
import { Link } from 'react-router-dom'
import { FaMapMarkerAlt } from "react-icons/fa";


const Footer = () => {
    return (
        <>
            <div className="footer">
                <div className="footer-top">
                    <div className="footer-top-details">
                        <div className="footer-top-details-image"></div>
                        <div className="footer-top-details-description"><p>Get in touch with India's leading labour law cosultants SP Solicitor. We support our clients in complete Labour law compliances.</p></div>
                        <div className="footer-top-details-links"></div>
                    </div>
                    <div className="footer-top-links">
                        <h1>Quick Link</h1>
                        <div className="footer-top-links-content">
                            <ul className='footer-top-links-content-first'>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/">Services</Link></li>
                                <li><Link to="./Inquiry">Inquiry</Link></li>
                            </ul>
                            <ul className='footer-top-links-content-second'>
                                <li><Link to="./AboutUs">About Us</Link></li>
                                <li><Link to="./LegalUpdates">Legal Updates</Link></li>
                                <li><Link to="./ContactUs">Contact us</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-top-address">
                        <div className="footer-top-address-heading">
                            <h1>Get In Touch</h1>
                        </div>
                        <div className="footer-top-address-main-content">
                            <ul className='footer-top-address-main-content-list'>
                                <li className='footer-top-address-main-content-address'><p><FaMapMarkerAlt />1st Floor, Sharma Market opposite ESIC Hospital, Sector 22, Noida, Uttar Pradesh - 201301</p></li>
                                <li className='footer-top-address-main-content-phone'><a href="tel:+918010549275"><p>+918010549275</p></a></li>
                                <li className='footer-top-address-main-content-mail'><a href="mailto:spsolicitor7@gmail.com"><p>spsolicitor7@gmail.com</p></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="footer-bottom-content">2024 © Copyright Nyayashastra Associates. All Rights Reserved.</div>
                </div>
            </div >
        </>
    )
}

export default Footer