import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const CertificationOrder = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>Certification Of Standing Order</h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>Certification Of Standing Order</h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        Welcome to SP Solicitor, your trusted partner in the meticulous process of Certification of Standing Orders. In the realm of industrial and employment regulations, the establishment and certification of standing orders are essential for fostering a transparent and harmonious relationship between employers and employees. At SP Solicitor, we specialize in guiding businesses through the intricacies of drafting and certifying standing orders, ensuring compliance with legal requirements. <br />
                        Certification of Standing Orders is a crucial step in defining the terms and conditions of employment within an organization. At SP Solicitor, our experienced team understands the significance of this process in promoting fair labor practices and resolving potential disputes. We offer comprehensive services that encompass the drafting of standing orders tailored to your organization's unique needs, as well as expert guidance through the certification process, ensuring that your standing orders align seamlessly with legal frameworks. <br />
                        Choosing SP Solicitor for Certification of Standing Orders means choosing a commitment to clarity, fairness, and legal precision. Our experts collaborate closely with businesses to understand their specific requirements, providing strategic advice to create standing orders that reflect both legal compliance and organizational culture. Trust SP Solicitor to be your reliable partner in navigating the complexities of the Certification of Standing Orders, helping you establish a foundation for a positive and legally compliant work environment


                    </p>
                </div>
            </div>
        </div>
    )
}

export default CertificationOrder