import React from 'react'

const Inquiry = () => {
    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent the default form submission
        window.alert('Submitted successfully'); // Show the alert message
    }
    return (
        <div className='contact-container'>
            <form action="" className='contact-left' onSubmit={handleSubmit}>
                <div className='contact-left-title'>
                    <h2>Get in touch</h2>
                    <hr />
                </div>
                <input type='text' name='name' placeholder='Your Name' className='contact-input' required />
                <input type='email' name='email' placeholder='Your Email' className='contact-input' required />
                <textarea name="message" placeholder='Your Message' className='contact-input' required ></textarea>
                <button type='submit'>Submit</button>
            </form>
            <div className='contact-right'>

            </div>
        </div>
    )
}

export default Inquiry