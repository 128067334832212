import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const SubmissionReturn = () => {
    return (
        <div className="AboutUs">
            <div className="AboutUs-image">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            style={{ maxHeight: '500px' }}
                            src="https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="pf" />
                        <Carousel.Caption>
                            <h3>Submission Of Returns </h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="AboutUs-Content">
                <div className="AboutUs-Content-Heading"><h3>Submission Of Returns </h3></div>
                <div className="AboutUs-Content-Info">
                    <p>
                        Welcome to SP Solicitor, your reliable partner for the seamless submission of returns. We understand the importance of timely and accurate returns in the realm of legal compliance, and our dedicated team is committed to ensuring that your organization navigates the intricacies of submission with precision and efficiency.
                        At SP Solicitor, we offer a comprehensive suite of services designed to cater to the diverse needs of businesses when it comes to the submission of returns. Whether it's tax returns, statutory returns, or industry-specific returns, our experienced professionals are well-versed in the latest regulatory requirements. Our goal is to simplify the often complex process of return submissions, allowing you to focus on your core business activities while ensuring compliance with legal obligations. <br />
                        Choosing SP Solicitor as your partner for the submission of returns means choosing a commitment to accuracy, timeliness, and regulatory excellence. Our team works diligently to stay updated on the latest changes in regulatory frameworks, enabling us to provide proactive advice and guidance. Trust SP Solicitor to be your dependable ally in navigating the world of return submissions, ensuring that your organization not only meets legal requirements but also sets the stage for sustained success.


                    </p>
                </div>
            </div>
        </div>
    )
}

export default SubmissionReturn